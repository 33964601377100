import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n';
import axios from 'axios';


require('./assets/bootstrap/css/bootstrap.min.css')
require('./assets/css/style.css')

const i18n = createI18n({
    locale: 'en',
    messages: {
        en: {
            navHome: 'HOME',
            navAbout: 'ABOUT',
            navService: 'SERVICE',
            navToken: 'TOKEN',
            navSwap: 'SWAP',
            navTeam: 'TEAM',
            navBoard: 'BOARD',
            navStaking: 'STAKING',
            homeMain: 'Experience the new paradigm of webtoons, only on MEMETOON!',
            homeBtn: 'LEARN MORE',
            homeSubtitle: "An innovative comic platform tailored for both creators and subscribers",
            homeSub: "MEMETOON PROJECT, built on blockchain technology, ensure that creators are no longer mere laborers in a webcomic factory. Instead, they can fully unleash their creativity and artistic sensibilities while safeguarding their works' copyright rights. This innovation contributes to a new paradigm shift in the webcomic market.",
            homeWhitepaperEn: 'Whitepaper(EN)',
            homeWhitepaperKo: 'Whitepaper(KO)',
            homeWhitepaperCh: 'Whitepaper(CH)',
            aboutSubtitle: 'A Fresh Encounter of Webtoon and Meme',
            aboutSub1: '"Memetoon" is a coined term that combines webtoon and "MEME," signifying a novel form of webcomic service.',
            aboutSub2: '"MEME" refers to cultural elements that become popular on the internet, spreading rapidly through forms such as images, videos, and text, expressing humor, social phenomena, culture, and more.',
            aboutSub3: 'MEMETOON is expressed in the form of webcomics, offering distinct styles, personalized artistry, and creatively crafted storylines. It encompasses various themes and humor, presenting characteristics like satirizing societal issues or playfully mocking reality.',
            aboutSub4: 'As a new genre of webcomics, Memetoon is gaining widespread popularity. It not only brings enjoyment and laughter to users but also offers diverse content and creators the opportunity for creative expression.',
            serviceNftTitle: 'NFT',
            serviceNftDetail: 'Non-Fungible Token',
            serviceNftSub: "NFT holders not only obtain the ownership and protection of the work, but also participate in the author's creative activities and follow-up activities, providing an interesting way of participation.",
            serviceNftSub1: 'Collecting Artworks as NFT',
            serviceNftSub2: 'Author Biography NFT',
            serviceNftSub3: 'NFTs for Various Activity Participation',
            serviceNftSub4: 'NFTs for Supporting Authors',
            serviceAiTitle: 'AI',
            serviceAiDetail: 'Artificial Intelligence',
            serviceAiSub: 'Artificial intelligence is transforming webcomics. Enjoy global stories without language barriers, enrich webcomic creation with AI intelligence, and immerse yourself through emotionally evocative AI background music and sound effects that enhance the atmosphere.',
            serviceAiSub1: 'Artificial intelligence',
            serviceAiSub2: 'Creative Support',
            serviceAiSub3: 'BGM Sound Support',
            serviceTokenTitle: 'Tokens & MT & Staking',
            serviceTokenDetail: 'Token Service',
            serviceTokenSub: 'MEMETOON tokens can be exchanged for MEMETOON Point to be used in the services provided by MEMETOON. Additionally, MEMETOON tokens can be utilized for staking services, allowing participants to earn a predetermined amount of fixed income based on the duration of their staking period. The earnings are contingent upon the length of the staking period.',
            serviceTokenSub1: '30days - 0.5%',
            serviceTokenSub2: '90days - 2%',
            serviceTokenSub3: '200days - 5%',
            serviceTokenSub4: '365days - 10%',
            tokenMenuTitle1: 'Join Membership',
            tokenMenuSub1: 'Exciting membership registration privileges starting from 500 MEMETOON tokens! Join now! (Limited to 10,000 KYC certified)',
            tokenMenuTitle2: 'Check-in',
            tokenMenuSub2: 'Accumulate 5 MT by checking in every day!',
            tokenMenuTitle3: 'Activity',
            tokenMenuSub3: 'Participate in activities such as commenting, giving star ratings, or recommending to earn a 10 MT activity reward~!',
            tokenDistributionTitle: 'Token Distribution Plan',
            tokenDistributionSub: "The token distribution plan outlines how token rewards are allocated within the platform. It provides a fair and efficient distribution method, taking into account participants' contributions and roles.",
            tokenDistributionSub1: 'Marketing(5%) / (2%) - 48mo lock-up & monthly subscription & 3% initial distribution allocation',
            tokenDistributionSub2: 'Team(5%) - 48mo lock-up & monthly subscription',
            tokenDistributionSub3: 'Advisor(5%) - 48mo lock-up & monthly subscription',
            tokenDistributionSub4: 'R&D(10%) - 48mo lock-up & monthly subscription',
            tokenDistributionSub5: 'Foundation(20%) - 48mo lock-up & monthly subscription',
            tokenDistributionSub6: 'Token Sale(10%) - during the sale 36mo lock-up & monthly subscription',
            tokenDistributionSub7: 'Eco-system(15%) - 36mo lock-up & monthly subscription',
            tokenDistributionSub8: 'Staking(20%) - 36mo lock-up & monthly subscription',
            tokenDistributionSub9: 'Reward(10%) - 36mo lock-up & monthly subscription',
            // tokenDistributionSub10: 'Initial(3%) - 2mo lock-up',
            // tokenDistributionSub11: 'Test(0%) - 3~36mo lock-up',
            teamRole1: 'Co-founder / CEO / CFO',
            teamName1: 'PIAO CHENGGUAN',
            teamProfile1: 'Founder / Chief Executive Officer / Chief Financial Officer',
            teamRole2: 'COO',
            teamName2: 'DUCKBO KIM',
            teamProfile2: 'Chief Platform Officer',
            teamRole3: 'CCO',
            teamName3: 'BOOHYUN KIM',
            teamProfile3: 'Chief Content Officer',
            teamRole4: 'CTO / CMO',
            teamName4: 'MEI HAITAO',
            teamProfile4: 'Chief Technology Officer / Chief Marketing Officer',
            
            swapTitle1: 'COMMING',
            swapTitle2: 'SOON',
            swapSub1: 'We are currently preparing our trading services.',
            swapSub2: 'Please wait, you will soon be able to use MEMETOON swap for trading services.',
            swapSubDetail: ' - Expected to open in October to November - ',
            swapBtn: 'Learn BHCC',
            contactTitle: 'CONTACT',
            contactFirstName: 'First Name',
            contactLastName: 'Last Name',
            contactEmail: 'Email',
            contactMessage: 'Message',
            contactSubmit: 'SUBMIT',
            stakingConnect: 'Connect Wallet',
            stakingConnected: 'Connected',
            stakingButton: 'Staking',
            stakingClaim: 'Claim',
            stakingUnstaking: 'Unstaking',
            stakingNone: 'There is no ongoing staking.',
            stakingAlertWalletConnect: 'Please connect your wallet.',
            stakingAlertReject: 'Approval is in progress. Please check again later.',
            stakingApproveSuccess: 'Approval granted! Begin your staking process.',
            stakingAlertSuccess: 'Staking success.',
            staking30day: '30days',
            staking90day: '90days',
            staking200day: '200days',
            staking365day: '365days',
            stakingLimit: 'Staking Amount Limitation',
            stakingMin: 'Minimum',
            stakingMax: 'Maximum',
            stakingSummary: 'Summary',
            stakingDisabled: 'Enter quantity',
            stakingName: 'Name',
            stakingTotalAmount: 'Total Amount',
            stakingStartDate: 'Start Date',
            stakingEndDate: 'End Date',
            stakingReward: 'Reward Amount',
            stakingLockedPeriod: 'Locked Period(Days)',
            stakingStatus: 'Status',
            stakingClose: 'Terminated',
            stakingAlertConfirm: 'Confirm',
            stakingAvailable: 'Available',
            stakingPopupText: 'Participants can stake MEMETOON tokens (MEME) and earn a predetermined profit based on the staking duration. The staking periods are divided into 30 days, 90 days, 200 days, and 365 days, with specific profits and quantities allocated for each period. The minimum staking amount is 10,000 tokens, and the maximum is 100 million tokens.',
            stakingPeriod: 'Staking Period',
            stakingMaturityQuantity: 'Expected Maturity Quantity',
            stakingDuration: 'Duration',
            stakingLockAmount: 'Locked Amount',
            stakingApprove: 'Approve',
            stakingAlertMax: 'Exceeded the maximum staking amount.',
            stakingAlertInsufficient: 'Insufficient quantity.',
            day: 'day',
            boardMemeHome: 'MEMETOON',
            boardTitleAll: 'All',
            boardTitleNotice: 'Notice',
            boardTitleNews: 'News',
            boardTitleEvent: 'Event',
            boardTitleDisclosure: 'Disc',
            boardNone: 'No posts.',
            boardAddPost: 'Add Post',
            boardAddTitle: 'Title',
            boardAddCategory: 'Category',
            boardCancel: 'Cancel',
            telephone: 'Tel: 02-515-2622',
            address: 'Address : 14F, 105, 428, Seolleung-ro, Gangnam-gu',

            partners_title1: 'Legal & Accounting',
            partners_title2: 'Media & Press',
            partners_title3: 'Exchange & Security Audit',
            partners_title4: 'Contents',
            
        },
        ko: {
            navHome: 'HOME',
            navAbout: 'ABOUT',
            navService: 'SERVICE',
            navToken: 'TOKEN',
            navSwap: 'SWAP',
            navTeam: 'TEAM',
            navBoard: 'BOARD',
            navStaking: 'STAKING',
            homeMain: '웹툰의 새로운 패러다임, 밈툰을 만나보세요!',
            homeBtn: 'LEARN MORE',
            homeSubtitle: '작가와 구독자를 위한 혁신적인 웹툰 플랫폼',
            homeSub: 'MEMETOON 프로젝트(MEME)는 블록체인이라는 신뢰 시스템 아래 작가는 웹툰 공장의 노동자가 아닌 자신의 창의성, 예술적 감각을 살려 만든 작품의 저작권 권리를 보장해줌으로써 웹툰 시장의 새로운 패러다임 혁신에 이바지 하려 합니다.',
            homeWhitepaperEn: 'Whitepaper(EN)',
            homeWhitepaperKo: 'Whitepaper(KR)',
            homeWhitepaperCh: 'Whitepaper(CH)',
            aboutSubtitle: '웹툰(Webtoon)과 밈(MEME)의 새로운 만남',
            aboutSub1: '"밈툰"은 웹툰과 "밈(MEME)"을 결합한 용어로, 새로운 형태의 웹툰 서비스를 의미합니다.',
            aboutSub2: '밈(MEME)이란 인터넷에서 유행하는 문화 요소를 말하며, 유머, 사회적 현상, 문화 등을 표현하는데 사용되는 이미지, 동영상, 글 등의 형태로 빠르게 퍼지는 것을 말합니다.',
            aboutSub3: '밈툰은 이러한 밈을 웹툰의 형식으로 표현하는 것으로, 색다른 스타일과 개성적인 그림체, 독특한 이야기를 담은 웹툰을 제공하며, 다양한 주제와 유머를 다룸으로써 사회적 이슈를 다루거나 현실을 풍자하는등의 특징이 있습니다.',
            aboutSub4: '밈툰은 새로운 웹툰의 장르로 인기를 얻고 있으며, 유저들에게 즐거움과 웃음을 제공하는 동시에 다양한 콘텐츠와 창작자들에게도 창의적인 표현의 기회를 제공하고 있습니다.',
            serviceNftTitle: 'NFT',
            serviceNftDetail: 'Non-Fungible Token',
            serviceNftSub: 'NFT를 소유자에게는 해당 작품의 소유권을 보장하며, 동시에 작가의 활동과 후속 작품의 지원까지 함께 참여할 수 있는 재미를 재공합니다.',
            serviceNftSub1: '작품 소장 NFT',
            serviceNftSub2: '작가 프로필 NFT',
            serviceNftSub3: '각종 행사 참여 NFT',
            serviceNftSub4: '작가 지원 NFT',
            serviceAiTitle: 'AI',
            serviceAiDetail: 'Artificial Intelligence',
            serviceAiSub: 'AI가 웹툰을 변화시킵니다. 언어 장벽 없이 글로벌한 이야기를 즐기고, 창작에 AI의 지능을 활용하여 웹툰을 더욱 풍부하게 만들며, 감동과 분위기를 높여줄 AI BGM 사운드로 몰입하세요.',
            serviceAiSub1: 'AI 번역',
            serviceAiSub2: '창작 지원',
            serviceAiSub3: 'BGM 사운드 지원',
            serviceTokenTitle: '토큰 & MT & 스테이킹',
            serviceTokenDetail: 'Token Service',
            serviceTokenSub: 'MEMETOON 토큰(MEME)은 MEMETOON 프로젝트(MEME) 포인트로 교환하여 MEMETOON 프로젝트(MEME)에서 제공하는 서비스를 이용할 수 있습니다. 또한, MEMETOON TOKEN(MEME)으로 스테이킹 서비스를 이용할 수 있으며, 스테이킹 기간에 따라 일정량 정해진 이익을 얻을 수 있습니다.',
            serviceTokenSub1: '30일 - 0.5%',
            serviceTokenSub2: '90일 - 2%',
            serviceTokenSub3: '200일 - 5%',
            serviceTokenSub4: '365일 - 10%',
            tokenMenuTitle1: '회원가입',
            tokenMenuSub1: '500 MEMETOON 토큰(MEME)으로 시작하는 멋진 회원가입 혜택! 지금 바로 가입하세요! (10,000명KYC 인증한정)',
            tokenMenuTitle2: '출석체크',
            tokenMenuSub2: '출석체크로 매일 매일 5 Point를 적립하세요~!',
            tokenMenuTitle3: '활동',
            tokenMenuSub3: '댓글, 별점 또는 추천등의 활동으로 밈툰 활성화 보상(10 Point)을 받아가세요~!',
            tokenDistributionTitle: 'Token Distribution Plan',
            tokenDistributionSub: '토큰 분배 계획은 플랫폼 내에서 토큰 보상을 어떻게 할지를 결정하는 계획입니다. 참여자의 기여도와 역할을 고려하여 공정하고 효율적인 분배 방식을 제시합니다.',
            tokenDistributionSub1: 'Marketing(5%) / (2%) - 48개월 락업 & 매월 해제 & 3% 초기유통량 형성 사용',
            tokenDistributionSub2: 'Team(5%) - 48개월 락업 & 매월 해제',
            tokenDistributionSub3: 'Advisor(5%) - 48개월 락업 & 매월 해제',
            tokenDistributionSub4: 'R&D(10%) - 48개월 락업 & 매월 해제',
            tokenDistributionSub5: 'Foundation(20%) - 48개월 락업 & 매월 해제',
            tokenDistributionSub6: 'Token Sale(10%) - 세일 시 36개월 락업 & 매월 해제',
            tokenDistributionSub7: 'Eco-system(15%) - 36개월 락업 & 매월 해제',
            tokenDistributionSub8: 'Staking(20%) - 36개월 락업 & 매월 해제',
            tokenDistributionSub9: 'Reward(10%) - 36개월 락업 & 매월 해제',
            // tokenDistributionSub10: 'Initial(3%) - 3개월 락업',
            // tokenDistributionSub11: 'Test(0%) - 3~36개월 락업',
            teamRole1: 'Co-founder / CEO / CFO',
            teamName1: 'PIAO CHENGGUAN',
            teamProfile1: '창립자 / 경영 / 재무 최고 책임자',
            teamRole2: 'COO',
            teamName2: 'DUCKBO KIM',
            teamProfile2: '플랫폼 운영 최고 책임자',
            teamRole3: 'CCO',
            teamName3: 'BOOHYUN KIM',
            teamProfile3: '컨텐츠 최고 책임자',
            teamRole4: 'CTO / CMO',
            teamName4: 'MEI HAITAO',
            teamProfile4: '기술 / 마케팅 최고 책임자',
            swapTitle1: 'COMMING',
            swapTitle2: 'SOON',
            swapSub1: '현재 스왑 서비스를 준비중입니다.',
            swapSub2: '조금만 기다려 주시면 MEMETOON 프로젝트(MEME)에서 준비한 스왑서비스를 이용할 수 있습니다.',
            swapSubDetail: '- 10월~11월 오픈 예정 -',
            swapBtn: 'LEARN BHCC',
            contactTitle: 'CONTACT',
            contactFirstName: 'First Name',
            contactLastName: 'Last Name',
            contactEmail: 'Email',
            contactMessage: 'Message',
            contactSubmit: 'SUBMIT',
            stakingConnect: '지갑 연결하기',
            stakingConnected: '연결됨',
            stakingButton: '스테이킹',
            stakingClaim: '청구하기',
            stakingUnstaking: '언스테이킹',
            stakingNone: '진행 중인 스테이킹이 없습니다.',
            stakingAlertWalletConnect: '지갑을 연결해주세요.',
            stakingAlertReject: '승인중입니다 잠시후 다시 확인해주세요.',
            stakingApproveSuccess: '승인 허가! 스테이킹을 시작하세요.',
            stakingAlertSuccess: '스테이킹 성공.',
            staking30day: '30일',
            staking90day: '90일',
            staking200day: '200일',
            staking365day: '365일',
            stakingLimit: '스테이킹 수량 제한',
            stakingMin: '최소',
            stakingMax: '최대',
            stakingSummary: '요약',
            stakingDisabled: '수량을 입력해주세요',
            stakingName: '이름',
            stakingTotalAmount: '총 수량',
            stakingStartDate: '시작 날짜',
            stakingEndDate: '종료 날짜',
            stakingReward: '보상 수량',
            stakingLockedPeriod: '잠금 기간(일)',
            stakingStatus: '상태',
            stakingClose: '종료됨',
            stakingAlertConfirm: '확인',
            stakingAvailable: '사용가능',
            stakingPopupText: '참여자는 MEMETOON 토큰(MEME)을 스테이킹 할 수 있으며, 스테이킹 기간에 따라 일정 량의 정해진 이익을 얻을 수 있습니다. 스테이킹 기간은 30일, 90일, 200일, 365일로 나누어지며, 각 기간에 따른 이익과 수량이 정해져 있습니다. 최소 스테 이킹 수량은 1만개이며, 최대는 1억개입니다',
            stakingPeriod: '스테이킹 기간',
            stakingMaturityQuantity: '만기 예상수량',
            stakingDuration: '기간',
            stakingLockAmount: '잠금 수량',
            stakingApprove: '승인',
            stakingAlertMax: '최대 스테이킹 수량을 초과하였습니다.',
            stakingAlertInsufficient: '보유 수량이 부족합니다.',
            day: '일',
            boardMemeHome: 'MEMETOON',
            boardTitleAll: '전체',
            boardTitleNotice: '공지',
            boardTitleNews: '소식',
            boardTitleEvent: '이벤트',
            boardTitleDisclosure: '공시',
            boardNone: '게시물이 없습니다.',
            boardAddPost: '게시글 등록',
            boardAddTitle: '제목',
            boardAddCategory: '카테고리',
            boardCancel: '취소',
            telephone: '전화번호: 02-515-2622',
            address: '주소 : 강남구 선릉로 428, 워워크선릉3호점 14층 105호',

            partners_title1: '법률 & 회계',
            partners_title2: '미디어 & 언론',
            partners_title3: '거래소 & 보안감사',
            partners_title4: '콘텐츠',
        },
        ch: {
            navHome: '首页',
            navAbout: '关于',
            navService: '服务',
            navToken: '通证',
            navSwap: '去中心化交易',
            navTeam: '团队',
            navBoard: '董事会',
            navStaking: '质押',
            homeMain: '体验网络漫画的新范式, 尽在MEMETOON!',
            homeBtn: '了解更多',
            homeSubtitle: '为作家和订阅者打造的创新漫画平台',
            homeSub: 'MEMETOON 项目, 确保作家不再是网络漫画工厂的劳工, 而是能够充分发挥自己的创造力和艺术感觉, 保障他们作品的版权权利, 从而促进网络漫画市场的新范式革新.',
            homeWhitepaperEn: '白皮书(英文)',
            homeWhitepaperKo: '白皮书(韩文)',
            homeWhitepaperCh: '白皮书(中文)',
            aboutSubtitle: 'Webtoon与Meme的全新相遇',
            aboutSub1: '"Memetoon"是网络漫画和“MEME”的组合术语, 意味着一种新型网络漫画服务.',
            aboutSub2: '"MEME"是指在互联网上流行的文化元素，以图像、视频、文字等形式表达幽默、社会现象、文化等，迅速传播开来的现象.',
            aboutSub3: 'MEME以网络漫画的形式表现出来, 提供了独特的风格、个性化的绘画风格以及富有创意的故事情节. 以及各种主题和幽默, 通过讽刺社会问题或揶揄现实等特点来呈现.',
            aboutSub4: 'Memetoon作为一种新的网络漫画流派正受到广泛欢迎, 它不仅为用户提供乐趣和笑声, 同时也为多样化的内容和创作者提供了创意表达的机会.',
            serviceNftTitle: 'NFT',
            serviceNftDetail: '不可替代的代币',
            serviceNftSub: 'NFT的持有者不仅获得作品的所有权和保障, 同时还能参与到作者的创作活动和后续活动中, 提供一种有趣的参与方式.',
            serviceNftSub1: '作品收藏 NFT',
            serviceNftSub2: '作者简介 NFT',
            serviceNftSub3: '各种活动参与的 NFT',
            serviceNftSub4: '支持作者的 NFT',
            serviceAiTitle: 'AI',
            serviceAiDetail: '人工智能',
            serviceAiSub: '人工智能正在改变网络漫画. 无语言障碍地享受全球化的故事, 利用人工智能的智能丰富网络漫画创作, 通过感人和氛围浓厚的AI背景音乐音效, 沉浸其中.',
            serviceAiSub1: '人工智能翻译',
            serviceAiSub2: '创意支持',
            serviceAiSub3: 'BGM音效支持',
            serviceTokenTitle: '代币 & MT & 质押',
            serviceTokenDetail: '通证服务',
            serviceTokenSub: 'MEME代币可以通过兑换为 MEME点, 以便在MEMETOON提供的服务中使用. 此外, 还可以使用MEME代币进行质押服务, 在质押期间可以获得一定数量的固定收益, 收益取决于质押的持续时间.',
            serviceTokenSub1: '30天 - 0.5%',
            serviceTokenSub2: '90天 - 2%',
            serviceTokenSub3: '200天 - 5%',
            serviceTokenSub4: '365天 - 10%',
            tokenMenuTitle1: '加入会员',
            tokenMenuSub1: '从500个MEME代币开始的精彩会员注册特权! 立即加入吧! (限10,000人KYC认证)',
            tokenMenuTitle2: '签到',
            tokenMenuSub2: '通过每天签到累积5个MT吧!',
            tokenMenuTitle3: '活动',
            tokenMenuSub3: '通过评论、星级评价或推荐等活动, 获得10个MT的活跃度奖励~!',
            tokenDistributionTitle: '代币分配计划',
            tokenDistributionSub: '代币分发计划是关于在平台内如何分配代币奖励的规划. 它提供了一个公平而高效的分发方式, 考虑参与者的贡献和角色.',
            tokenDistributionSub1: '营销(5%) / (2%)- 48个月锁定期 & 每月订阅 & 3% 初始分配分配',
            tokenDistributionSub2: '团队(5%)- 48个月锁定期 & 每月订阅',
            tokenDistributionSub3: '顾问(5%)- 48个月锁定期 & 每月订阅',
            tokenDistributionSub4: '研发(10%)- 48个月锁定期 & 每月订阅',
            tokenDistributionSub5: '基金会(20%)- 48个月锁定期 & 每月订阅',
            tokenDistributionSub6: '代币销售(10%)- 销售时 36个月锁定期 & 每月订阅',
            tokenDistributionSub7: '生态系统(15%)- 36个月锁定期 & 每月订阅',
            tokenDistributionSub8: '质押(20%)- 36个月锁定期 & 每月订阅',
            tokenDistributionSub9: '奖励(10%)- 36个月锁定期 & 每月订阅',
            // tokenDistributionSub10: '最初的(3%)- 3个月锁定期',
            // tokenDistributionSub11: '测试(0%)- 3~36个月锁定期',
            teamRole1: 'Co-founder / CEO / CFO',
            teamName1: 'PIAO CHENGGUAN',
            teamProfile1: '创始人 / 首席执行官 / 首席财务官',
            teamRole2: 'COO',
            teamName2: 'DUCKBO KIM',
            teamProfile2: '首席平台官员',
            teamRole3: 'CCO',
            teamName3: 'BOOHYUN KIM',
            teamProfile3: '首席内容官',
            teamRole4: 'CTO / CMO',
            teamName4: 'MEI HAITAO',
            teamProfile4: '首席技术官 / 首席营销官',
            swapTitle1: '即将推出',
            swapTitle2: '',
            swapSub1: '当前正在准备交易服务.',
            swapSub2: '请稍等, 您很快就可以使用MEMETOON swap进行交易服务了.',
            swapSubDetail: '- 预计在10月至11月开放 -',
            swapBtn: '学习BHCC',
            contactTitle: 'CONTACT',
            contactFirstName: '姓氏',
            contactLastName: '名字',
            contactEmail: '邮箱',
            contactMessage: '消息',
            contactSubmit: '提交',
            stakingConnect: '连接钱包',
            stakingConnected: '已连接',
            stakingButton: '质押',
            stakingClaim: '索取',
            stakingUnstaking: '取消质押',
            stakingNone: '没有正在进行的质押。',
            stakingAlertWalletConnect: '请连接您的钱包。',
            stakingAlertReject: '正在进行批准。请稍后再次检查。',
            stakingApproveSuccess: '批准已授予！开始您的质押过程。',
            stakingAlertSuccess: '质押成功。',
            staking30day: '30天',
            staking90day: '90天',
            staking200day: '200天',
            staking365day: '365天',
            stakingLimit: '锁定金额限制',
            stakingMin: '最小',
            stakingMax: '最大',
            stakingSummary: '总结',
            stakingDisabled: '输入数量',
            stakingName: '名称',
            stakingTotalAmount: '总金额',
            stakingStartDate: '开始日期',
            stakingEndDate: '结束日期',
            stakingReward: '奖励金额',
            stakingLockedPeriod: '锁定期限(天)',
            stakingStatus: '状态',
            stakingClose: '已结束',
            stakingAlertConfirm: '确认',
            stakingAvailable: '可用',
            stakingPopupText: '参与者可以锁定MEMETOON代币（MEME）并根据锁定期限获得预定的利润。锁定期分为30天、90天、200天和365天，每个期限都有特定的利润和数量分配。最低锁仓数量为10,000个代币，最高为1亿个代币。',
            stakingPeriod: '质押期限',
            stakingMaturityQuantity: '到期预期数量',
            stakingDuration: '时长',
            stakingLockAmount: '锁定数量',
            stakingApprove: '批准',
            stakingAlertMax: '超过最大质押金额。',
            stakingAlertInsufficient: '数量不足。',
            day: '天',
            boardMemeHome: 'MEMETOON',
            boardTitleAll: '全部',
            boardTitleNotice: '通知',
            boardTitleNews: '新闻',
            boardTitleEvent: '活动',
            boardTitleDisclosure: '披露',
            boardNone: '暂无帖子',
            boardAddPost: '添加帖子',
            boardAddTitle: '标题',
            boardAddCategory: '类别',
            boardCancel: '取消',
            telephone: 'Tel: 02-515-2622',
            address: 'Address : 14F, 105, 428, Seolleung-ro, Gangnam-gu',

            partners_title1: '法律 & 会计',
            partners_title2: '媒体 & 新闻',
            partners_title3: '交易所 & 安全审计',
            partners_title4: '内容',

        }
    }
});

axios.defaults.baseURL = 'https://memetoon.io/backend'

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(router)
    .use(i18n)
    .mount('#app')
